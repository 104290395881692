<template>
    <div class="studyTask">
		
		
		<!-- <el-cascader :props="props" v-model="casc" collapse-tags></el-cascader> -->
		
        <div v-if="showstudent">
            <div class="studyTaskOper">
                <button @click="centerDialogVisible = true" class="cursorP">派发学习任务</button>
            </div>
            <table class="table1" cellspacing="0">
                <tr style="height:46px;background:#F9FAFC;">
                    <th style="width:71px;">ID</th>
                    <th style="width:216px;">课程标题</th>
                    <th style="width:86px;">课程类型</th>
                    <th style="width:83px;">学习人数</th>
                    <th style="width:176px;">计划完成时间</th>
                    <th style="width:140px;">创建时间</th>
                    <th style="width:136px;">操作项</th>
                </tr>
                <tr v-for="(im,ix) in studyTaskList.plan_info" :key="ix">
                    <td>{{im.id}}</td>
                    <td>{{im.title}}</td>
                    <td>{{im.type == 1 ? '直播' : '点播'}}</td>
                    <td>{{im.count}}</td>
                    <td>{{im.plan_time}}</td>
                    <td>{{im.ctime}}</td>
                    <td>
                        <p style="color:#3B9DFF;margin-bottom:8px;" class="cursorP" @click="startEdit(im)">编辑</p>
                        <p class="c33 cursorP" @click="seeparss(im)">查看进度</p>
                    </td>
                </tr>
            </table>
        </div>

        <div v-else style="text-align:left;margin-left:20px;">
            <button style="margin-top:20px;margin-bottom:30px;margin-right:20px;" class="cursorP" @click="exportExcel">导出Excel</button>
            <button @click="back" class="cursorP">返回</button>
            <table class="table3" cellspacing="0">
                <tr style="height:46px;background:#F9FAFC;">
                    <th>成员头像</th>
                    <th>成员姓名</th>
                    <th>成员勋章</th>
                    <th>积分数量</th>
                    <th>学习进度</th>
                </tr>
                <tr v-for="(im,ix) in studentList.user_info" :key="ix">
                    <td>
                        <img :src="im.portrait" style="width:60px;height:60px;border-radius:100px;" alt="">
                    </td>
                    <td>{{im.name}}</td>
                    <td>{{im.medal_name}}</td>
                    <td>{{im.point}}</td>
                    <td>{{im.rate == 0 ? '未开始' : im.rate}}</td>
                </tr>
            </table>
        </div>



        <el-dialog
			title="修改计划完成时间"
			:close-on-click-modal="false"
			:visible.sync="showEdit"
			width="620px"
			center>
			<div>
				<span class="" style="display:inline-block;width:150px;text-align:right;">课程：</span>
				{{tempInfo.title}}
			</div>
			<br>
			<div>
				<span style="display:inline-block;width:150px;text-align:right;">原计划完成时间：</span>{{tempInfo.plan_time}}
			</div>
			<div style="margin-top:20px;">
				<span style="display:inline-block;width:150px;text-align:right;">修改计划完成时间：</span>
				<el-date-picker
				style="width:330px;"
				v-model="plan_time"
				type="datetime"
				:picker-options="pickerOptions"
				value-format="yyyy-MM-dd HH:mm"
				placeholder="选择日期时间">
				</el-date-picker>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showEdit = false">取 消</el-button>
				<el-button type="primary" @click="editsure">确 定</el-button>
			</span>
        </el-dialog>



        <el-dialog
			title="派发学习任务"
			:close-on-click-modal="false"
			:visible.sync="centerDialogVisible"
			width="620px"
			center>
			<div class="selects">
				<div class="pfbtn" :class="pftype == 0 ? 'pfbtnCurr' : ''" @click="pftype = 0">按成员派发</div>
				<div class="pfbtn" :class="pftype == 1 ? 'pfbtnCurr' : ''" @click="pftype = 1">按部门派发</div>
				<table class="table2">
					<tr>
						<td style="width:130px;text-align:right;padding-right:24px;">课程名称</td>
						<td>
							<el-select style="width:330px;" v-model="hand_task.course_ids" multiple placeholder="请选择">
								<el-option
								v-for="item in allCourse"
								:key="item.id"
								:label="item.title"
								:value="item.id">
								</el-option>
							</el-select>
						</td>
					</tr>
					<tr v-if="pftype == 0">
						<td style="width:130px;text-align:right;padding-right:24px;">选择成员</td>
						<td>
							<el-select style="width:330px;" v-model="hand_task.student_ids" multiple placeholder="请选择成员">
								<el-option
								v-for="item in teamOrg"
								:key="item.id"
								:label="item.real_name"
								:value="item.id">
								</el-option>
							</el-select>
						</td>
					</tr>
					<tr v-else>
						<td style="width:130px;text-align:right;padding-right:24px;">选择部门</td>
						<td>
							<el-cascader placeholder="请选择部门" style="width: 330px;" :props="props" v-model="casc" collapse-tags></el-cascader>
						</td>
					</tr>
					<tr>
						<td style="width:130px;text-align:right;padding-right:24px;">计划完成时间</td>
						<td>
							<el-date-picker
							 style="width:330px;"
							v-model="hand_task.plan_time"
							type="datetime"
							:picker-options="pickerOptions"
							value-format="yyyy-MM-dd HH:mm"
							placeholder="选择日期时间">
							</el-date-picker>
						</td>
					</tr>
				</table>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="subnext">确 定</el-button>
			</span>
        </el-dialog>

        <el-dialog
            :visible.sync="parsonErrorHidden"
            width="700px"
            center>
            <div style="width:100%;text-align:left;">
                <p v-for="(im,ix) in parsonError" :key="ix" style="line-height:26px;font-size:16px;color:#333;">{{im.msg}}</p>
            </div>
            <div slot="footer">
                <el-button @click="parsonErrorHidden = false">关 闭</el-button>
            </div>
        </el-dialog>

    </div>
</template>
<script>
import { frame_list, add_next, new_next, organ_member_list, add_member, member_delall, new_branch } from '@/utils/Api/orgList'
import { study_task, my_teamOrg, hand_task, plan_member, new_plantime, export_plan_member, branch_hand_task } from '@/utils/Api/userList'
import { all_course } from '@/utils/Api/commonList'
	let id = 0;
export default {
    name:'studyTask',
    data(){
        return{
			pftype: 0, 				// 派发类别 0 按成员派发 1 按部门派发
			casc: [],
            editForm:{},
            studyTaskList:[],
            centerDialogVisible: false,
            allCourse:[],
            teamOrg:[],
            pickerOptions: {disabledDate: (time) => {return this.dealDisabledDate(time)}},
            hand_task:{},
            studentList:[],
            showstudent:true,
            showEdit: false,
            tempInfo:{},
            plan_time:'',       // 修改计划完成时间
            exportId:'',
            parsonError:[],     // 派发失败人员
            parsonErrorHidden: false,   // 派发失败弹窗
			props: {
				checkStrictly: true,
				multiple: true,
				value: 'id',
				label: 'name',
			    lazy: true,
			    lazyLoad (node, resolve) {
			        const { level, value } = node;
					if(level == 0){
						frame_list().then(res => {
							resolve(res)
						})
					}
					if(level != 0){
						frame_list({pid: value}).then(res => {
							res.forEach(jm => {
								jm.leaf = node.level >= 3
							})
							resolve(res)
						})
					}
			    }
			},
			branchHandTask:''
        }
    },
    created(){
        this.initial()
		this.frameList()
    },
	watch:{
		casc(){
			let arr = []
			this.casc.forEach((im,ix) => {
				arr.push({
					organ_id: this.user.id,
					level_one_id: im[0] ? im[0] : 0,
					level_two_id: im[1] ? im[1] : 0,
					level_three_id: im[2] ? im[2] : 0,
					level_four_id: im[3] ? im[3] : 0,
				})
			})
			this.branchHandTask = JSON.stringify(arr)
		}
	},
	computed:{
		user(){
			return this.$store.getters.personal
		}
	},
    methods:{
		frameList(params = {}){
			frame_list(params).then(res => {
				console.log(res)
			})
		},
        startEdit(im){
            this.showEdit = true
            console.log(im)
            this.tempInfo = im
        },
        async editsure(){
            let cbk = await new_plantime({course_id: this.tempInfo.course_id, plan_time:this.plan_time})
            console.log(cbk)
            if(cbk.length < 1){
                this.$message.success('修改成功')
                this.initial()
                this.showEdit = false
                return
            }
            this.$message.error('修改失败')
        },
        back(){
            this.showstudent = true
            this.scrollTop()
        },
        async seeparss(im){
            console.log(im)
            this.exportId = im.course_id
            let cbk = await plan_member({course_id:im.course_id})
            console.log(cbk)
            this.studentList = cbk
            this.showstudent = false
            this.scrollTop()
        },
        dealDisabledDate (time) {
            return time.getTime() < Date.now() - 8.64e7 || time.getTime() > Date.now() + (86400000 * 30 * 24)
        },
        async initial(){
            let res = await study_task()
            this.studyTaskList = res
            let teamOrg = await my_teamOrg({page:1, limit:9999})
			teamOrg.res.forEach((im,ix) => {
				im.real_name = im.real_name ? im.real_name + '_' + im.phone_tail : im.name + '_' + im.phone_tail
			})
            this.teamOrg = teamOrg.res
            let allCourse = await all_course()
            this.allCourse = allCourse
        },
        async subnext(){
            if(this.hand_task.course_ids.length < 1){
                this.$message.error('请选择课程名称！')
                return
            }
			if(this.pftype == 0 && this.hand_task.student_ids.length < 1){				
				this.$message.error('请选择成员！')
				return
			}
			if(this.pftype == 1 && !this.branchHandTask){
				this.$message.error('请选择部门！')
				return
			}
            if(!this.hand_task.plan_time){
                this.$message.error('请选择结束时间！')
                return
            }
			let cbks
			
			if(this.pftype == 0){
				cbks = await hand_task({
					course_ids: this.hand_task.course_ids.join(','),
					student_ids: this.hand_task.student_ids.join(','),
					plan_time: this.hand_task.plan_time
				})
			}
			
			if(this.pftype == 1){
				cbks = await branch_hand_task({
					course_ids: this.hand_task.course_ids.join(','),
					branch_info: this.branchHandTask,
					plan_time: this.hand_task.plan_time
				})
			}
			
			console.log(cbks)
			let cbk = cbks.data
			
            if(cbk.length < 1){
                this.$message.success('派发成功！')
                this.centerDialogVisible = false
                this.hand_task = {}
				this.casc = []
				this.branchHandTask = ''
                this.initial()
                return
            }else{
                this.$confirm('部分人员派发失败，是否查看失败原因？',{
                    cancelButtonText:'不用了',
                    confirmButtonText:'去看看',
                    type: 'warning'
                }).then(()=>{
                    this.parsonError = cbk
                    this.parsonErrorHidden = true
                }).catch(()=>{

                })
                this.initial()
                this.centerDialogVisible = false
                this.hand_task = {}
            }
        },
        async exportExcel(){
            window.open(`http://nmep.com.cn/api.php/api/Organ/export_plan_member?course_id=${this.exportId}&token=${this.$store.getters.token}`)
        }
    }
}
</script>
<style lang="scss" scoped>
	.pfbtn{
		font-size: 15px;
		color: #47D7E3;
		padding: 10px 20px;
		background: #fff;
		border-radius: 3px;
		display: inline-block;
		margin-left: 20px;
		cursor: pointer;
		border: 1px solid #47D7E3;
	}
	.pfbtnCurr{	background: #47D7E3!important;color: #FFFFFF; }
.table3{
    text-align: center;
    width: 913px;
    border: 1px solid #dfdfdf;
    th{
        font-size: 16px;
        color: #333;
        border-bottom: 1px solid #f3f3f3;
    }
    td{
        font-size: 14px;
        color: #333;
        border-bottom: 1px solid #f3f3f3;
    }
    tr{
        height: 100px;
    }
}
.table2{
    tr{
        height: 60px;
    }
}
.table1{
    color: #333;
    font-size: 16px;
    text-align: center;
    margin-left: 20px;
    margin-top: 22px;
    tr{
        height: 100px;
        td{
            border: 1px solid #dddddd;
        }
    }
}
button{
            font-size: 15px;
            color: #fff;
             padding: 10px 20px;
             background: #47D7E3;
             border-radius: 3px;
        }
.studyTask{
    .studyTaskOper{
        text-align: left;
        margin-left: 20px;
        margin-top: 20px;
        button{
            font-size: 15px;
            color: #fff;
             padding: 10px 20px;
             background: #47D7E3;
             border-radius: 3px;
        }
    }
}
</style>